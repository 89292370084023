import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/home/fa/star.svg'
import _imports_1 from '@/assets/home/fa/star-half.svg'


const _withScopeId = n => (_pushScopeId("data-v-764d09ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 0,
  src: _imports_1,
  alt: "",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("figure", {
    class: "review-stars",
    "aria-label": 'Rating: ' + _ctx.rating + ' stars'
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stars, (i) => {
      return (_openBlock(), _createElementBlock("img", {
        key: i,
        src: _imports_0,
        alt: "",
        "aria-hidden": "true"
      }))
    }), 128)),
    (_ctx.hasHalfStar)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}