
import { defineComponent } from "vue";
import { contacts } from "../../utils/home/contacts";

export default defineComponent({
  data() {
    return {
      contacts,
    };
  },
});
