
import { defineComponent } from "vue";
import { projects } from "@/utils/home/projects";

export default defineComponent({
  data() {
    return {
      projects,
    };
  },
});
