
import { defineComponent } from "vue";

type Skill = {
  name: string;
  experience: string;
  level: number;
};

const skills: Skill[] = [
  { name: "Vue.js", experience: "4 years", level: 100 },
  { name: "SCSS", experience: "3 years", level: 90 },
  { name: "Typescript", experience: "2 year", level: 95 },
  { name: "Unit testing", experience: "3 year", level: 90 },
  { name: "Basketball (for scale)", experience: "+20 years", level: 20 },
];

export default defineComponent({
  data() {
    return {
      skills,
    };
  },
});
