
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    rating: { type: Number, default: 5 },
  },
  computed: {
    stars(): number {
      return Math.floor(this.rating);
    },
    hasHalfStar(): boolean {
      return this.rating - this.stars > 0;
    },
  },
});
