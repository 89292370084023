
import { defineComponent } from "vue";
import Stars from "./Stars.vue";
import { reviews } from "@/utils/home/reviews";

export default defineComponent({
  components: {
    Stars,
  },
  data() {
    return {
      reviews,
    };
  },
});
