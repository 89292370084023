import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e121fed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "application" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_Reviews = _resolveComponent("Reviews")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createVNode(_component_Hero),
      _createVNode(_component_Skills),
      _createVNode(_component_Projects),
      _createVNode(_component_Reviews)
    ]),
    _createVNode(_component_Footer)
  ]))
}